.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.bounceIn {
    animation-duration: .7s; }
  .animated.bounceOut {
    animation-duration: .5s; }
  .animated.bounceIn {
    animation-name: bounceIn; }
  .animated.bounceOut {
    animation-name: bounceOut; }
  .animated.fadeIn {
    animation-name: fadeIn;
    animation-duration: .7s; }
  .animated.fadeOut {
    animation-name: fadeOut;
    animation-duration: .3s; }
  .animated.bounceInDown {
    animation-name: bounceInDown; }
  .animated.bounceOutUp {
    animation-name: bounceOutUp; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: perspective(1px) scale3d(1, 1, 1); } }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #60bb71;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #db6a64;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px; }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px; } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: .94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #60bb71; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #f7a336; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #58abc3; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #db6a64; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: white; }
  .redux-toastr .toastr.rrt-info {
    background-color: #58abc3; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #378298; }
  .redux-toastr .toastr.rrt-success {
    background-color: #60bb71; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #3e914d; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #f7a336; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #d87e09; }
  .redux-toastr .toastr.rrt-error {
    background-color: #db6a64; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #c5352e; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      -webkit-border-radius: 0 0 0 4px;
      -moz-border-radius: 0 0 0 4px;
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px; }

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px; }

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px; }

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px; }

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px; }

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative; }

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc; }

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px; }

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc; }

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3; }

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc; }

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px; }

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0; }

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer; }

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem; }

.react-datepicker__day {
  cursor: pointer; }

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0; }

.react-datepicker__day--today {
  font-weight: bold; }

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff; }

.react-datepicker__day--highlighted:hover {
  background-color: #32be3f; }

.react-datepicker__day--highlighted-custom-1 {
  color: magenta; }

.react-datepicker__day--highlighted-custom-2 {
  color: green; }

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff; }

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #1d5d90; }

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff; }

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90; }

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5); }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000; }

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc; }

.react-datepicker__day--disabled:hover {
  background-color: transparent; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer; }

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae; }

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer; }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3; }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px; }

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem; }

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem; } }

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem; }

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent; }

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default; }

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default; }

input[type="text"]:disabled, textarea:disabled, input[type="text"]:read-only, textarea:read-only {
  background-color: #f9f9f9;
  color: black; }

label > span {
  padding-left: 4px; }

button.w3-button.image {
  padding: 2px 8px;
  margin: 0px; }

textarea.w3-input {
  padding: 7px 8px; }

div.Select-control div.Select-value {
  line-height: 36px !important;
  text-align: left; }

.ReactTable {
  border: none !important;
  margin-top: 8px; }
  .ReactTable .rt-resizer {
    z-index: 0 !important; }
  .ReactTable .rt-table {
    border: 1px solid #ccc; }
  .ReactTable .rt-tr-group {
    border: none !important; }
  .ReactTable .rt-tr {
    border-bottom: 1px solid #ccc; }
  .ReactTable .rt-tr.green.-odd {
    background-color: #e7f3da !important; }
  .ReactTable .rt-tr.green.-even {
    background-color: #dcedc8 !important; }
  .ReactTable .rt-tr.red.-odd {
    background-color: #fbc6c2 !important; }
  .ReactTable .rt-tr.red.-even {
    background-color: #fcd9d6 !important; }
  .ReactTable .rt-tr.text-grey, .ReactTable .rt-tr.text-grey div.entry input {
    color: #aaa !important; }
  .ReactTable .rt-th > div {
    line-height: 30px;
    height: 30px; }
  .ReactTable .-header {
    background-color: #4CAF50;
    font-weight: bold;
    font-size: 15px;
    color: #fff; }
  .ReactTable .rt-noData {
    padding: 5px 10px !important;
    color: black !important;
    top: 60px !important;
    border-radius: 5px; }

.ReactTable.-highlight .rt-body .rt-tr:hover {
  background-color: #ccc !important; }

div.entry {
  padding: 0px !important;
  position: relative; }
  div.entry i.fa-info-circle, div.entry i.fa-pencil-alt {
    position: absolute;
    top: 10px;
    right: 7px;
    cursor: pointer; }

div.title {
  padding: 0px 5px !important;
  position: relative; }
  div.title div.icon {
    padding-right: 30px !important; }
  div.title i.fas {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer; }

input.title, input.title-entered, input.title-send, input.title-accepted, input.title-rejected {
  padding: 0px 5px !important;
  margin: 3px auto;
  color: black;
  height: 30px; }

input.title-entered {
  background-color: white; }

input.title-send {
  background-color: #bff !important; }

input.title-accepted {
  background-color: #bfb !important; }

input.title-rejected {
  background-color: #fbb !important; }

input.entry-entered, input.entry-send, input.entry-accepted, input.entry-rejected {
  padding: 0px !important;
  text-align: center;
  font-weight: bold;
  width: 50px;
  margin: 3px auto;
  color: black;
  height: 30px; }

input.entry-entered {
  background-color: white; }

input.entry-send {
  background-color: #bff !important; }

input.entry-accepted {
  background-color: #bfb !important; }

input.entry-rejected {
  background-color: #fbb !important; }

input.entry-legend {
  display: inline;
  width: 90px;
  font-weight: normal;
  margin: 0px 8px 0px 0px; }

input.entry-legend:last-child {
  margin-right: 0px; }

span.status-entered, span.status-send, span.status-accepted, span.status-rejected {
  color: #fff;
  border-radius: 4px; }

span.status-entered {
  color: black;
  background-color: #ccc; }

span.status-send {
  background-color: #2196f3; }

span.status-accepted {
  background-color: #4caf50; }

span.status-rejected {
  background-color: #f44336; }

div.week-header {
  line-height: 1.2;
  margin: -3px; }

.page-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end; }
  @media (max-width: 600px) {
    .page-filter {
      justify-content: stretch; } }
  .page-filter .form {
    width: 500px;
    padding: 8px; }
    @media (max-width: 600px) {
      .page-filter .form {
        width: 100%; } }
  .page-filter .check {
    height: 36px;
    line-height: 36px; }
  .page-filter .table-filter {
    display: block; }
    @media (max-width: 600px) {
      .page-filter .table-filter {
        width: 100%;
        text-align: center; } }
    @media (max-width: 800px) {
      .page-filter .table-filter {
        margin-top: 16px; } }

.form-inline {
  margin: 4px 0px; }
  .form-inline textarea {
    height: 100px; }
  .form-inline .label label {
    display: block;
    text-align: right;
    line-height: 36px;
    margin-right: 8px; }
    @media (max-width: 600px) {
      .form-inline .label label {
        text-align: left; } }
  .form-inline .value label {
    display: block;
    line-height: 36px; }
  .form-inline .value .select-inline:first-child {
    margin-left: 0px; }
  .form-inline p {
    padding: 8px 0px 7px 0px;
    margin: 0px; }

.react-datepicker .react-datepicker__day--sat {
  font-weight: bold;
  color: #2196F3; }

.react-datepicker .react-datepicker__day--sun {
  font-weight: bold;
  color: #f44336; }

.react-datepicker .react-datepicker__day--outside-month {
  color: #aaa !important; }

.react-datepicker .react-datepicker__day.holiday {
  color: #f44336; }

.login-form-bckg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 100px; }
  @media (max-width: 600px) {
    .login-form-bckg {
      padding: 36px 8px 0px 8px; } }

.login-form {
  margin: 0px auto;
  display: flex;
  justify-content: center; }

div.show-templates {
  position: relative; }
  div.show-templates p {
    text-align: center;
    display: block;
    cursor: pointer; }
    div.show-templates p:hover {
      color: #135a91; }

div.w3-panel p.small {
  margin-top: -8px;
  font-size: 12px;
  font-style: italic;
  color: #444; }

.modal-small {
  width: 350px; }

@media (min-width: 993px) {
  .modal-wide {
    width: 990px; } }

.input-inline, .select-inline {
  display: inline-block;
  margin: 0px 8px 0px 0px;
  width: auto; }

.input-inline {
  vertical-align: baseline; }

.select-inline {
  vertical-align: middle; }

label.inline, .label-inline {
  margin: 0px 8px 0px 0px; }

.small-bottom {
  padding-bottom: 0px; }

.small-top {
  margin-top: 0px; }

@media (max-width: 600px) {
  .m-center {
    text-align: center !important; } }

.pointer {
  cursor: pointer; }

.w100 {
  width: 100px; }

.w200 {
  width: 200px; }

.w308 {
  width: 308px; }

.h200 {
  height: 200px !important; }

.small-button {
  font-size: 13px;
  padding: 1px 5px;
  vertical-align: bottom; }

.moveUpMargin {
  margin-top: -20px; }

.mt100 {
  margin-top: 100px; }

.padbl6 {
  padding-bottom: 6px;
  padding-left: 6px; }

.code {
  font-family: "Consolas"; }

.w3-display-container .small-right {
  position: absolute;
  top: -2px;
  right: 16px;
  font-size: 14px; }

html {
  height: 100%; }

body {
  min-height: 100%;
  position: relative; }

.app-container {
  max-width: 1400px;
  padding-top: 98px;
  padding-bottom: 120px; }

.navbar-menu {
  color: #fff !important;
  background-color: #135a91; }

.w3-top.navbar.sticky .navbar-details {
  max-height: 60px; }

.w3-top.navbar .navbar-details {
  max-height: 0px;
  font-size: 13px;
  background-color: #bcdffb;
  transition: max-height 0.5s ease-in; }

img.bms-logo {
  height: 38px;
  padding: 2px !important;
  border-radius: 3px;
  background: #fff;
  box-shadow: 1px 1px 3px #000; }

div.ttrack-title {
  font-weight: bold;
  font-size: 20px;
  padding: 0px !important;
  margin: 4px 0px 0px 16px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); }

h1 {
  font-weight: bold;
  padding: 20px 0px;
  font-size: 32px;
  text-shadow: 1px 1px 2px #ccc; }

footer.footer {
  text-align: center;
  color: white;
  background-color: #135a91;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px; }

button.google {
  background: #dd4b39;
  color: white; }

.float-parent-element {
  display: flex;
  width: 90%; }

.float-child-element {
  display: flex;
  margin: auto;
  width: 50%; }

.float-parent-element2 {
  display: flex;
  width: 70%; }

.button-cursor-disabled {
  cursor: not-allowed; }

.button-display-only {
  padding: 2px 8px;
  border: none; }
